/**
 * Utility for debouncing.
 *
 * @example
 *
 * // 1.
 * const debouncedCallback = debounce(callback, 500);
 * superFastEvent.addListener(debouncedCallback);
 *
 *
 * // 2.
 * const changeHandler: CollectionChangeCallback = (_, changes) => {
 *  ...
 * }
 *
 * annotationsCollection.addListener(debounce(changeHandler, 1000));
 *
 */
export const debounce = <T extends Function>(callback: T, wait: number): ((...args: any[]) => void) => {
  let timeoutId: number | null = null;

  return (...args: any[]) => {
    window.clearTimeout(timeoutId ?? undefined);

    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};
