/**
 * Directly forked from https://github.com/mantinedev/mantine/blob/master/packages/@mantine/hooks/src/use-idle/use-idle.ts
 */
import { useEffect, useRef, useState } from "react";

/** Default events to listen to that signify as "active". */
const DEFAULT_EVENTS: (keyof DocumentEventMap)[] = [
  "keypress",
  "mousemove",
  "touchmove",
  "click",
  "scroll",
];

/** Default options for the `useIdle` hook. */
const DEFAULT_OPTIONS = {
  events: DEFAULT_EVENTS,
  initialState: true,
};

/**
 * Hook that tracks user idle state.
 * - Returns `true` when user is idle.
 * - Returns `false` when user is active.
 *
 * @param timeout - how many seconds until considered 'idle'.
 * @param options - options for the hook.
 */
export function useIdle(
  timeout: number,
  options?: Partial<{
    /**  Events to listen to that signify as "active". @defaultValue `keypress`, `mousemove`, `touchmove`, `click`, `scroll` */
    events: (keyof DocumentEventMap)[];
    /** Initial state. @defaultValue `true` */
    initialState: boolean;
  }>,
) {
  const { events, initialState } = { ...DEFAULT_OPTIONS, ...options };
  const [idle, setIdle] = useState<boolean>(initialState);
  const timer = useRef<number>();

  useEffect(() => {
    const handleEvents = () => {
      setIdle(false);

      if (timer.current) {
        window.clearTimeout(timer.current);
      }

      timer.current = window.setTimeout(() => {
        setIdle(true);
      }, timeout);
    };

    events.forEach((event) => document.addEventListener(event, handleEvents));

    // Start the timer immediately instead of waiting for the first event to happen
    timer.current = window.setTimeout(() => {
      setIdle(true);
    }, timeout);

    return () => {
      events.forEach((event) =>
        document.removeEventListener(event, handleEvents),
      );
    };
  }, [events, timeout]);

  return idle;
}
