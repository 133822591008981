/**
 * Valid values of a Business Status system preference.
 * - "open": Accept call from clients.
 * - "closed": Do not accept call from clients.
 * - "schedule": Determines if the business is open or closed based on the business hours.
 */
export enum BusinessStatus {
  OPEN = "open",
  CLOSE = "close",
  SCHEDULE = "schedule",
}

/**
 * Valid values of a Session Initiation Protocol system preference.
 */
export enum SessionInitiationProtocol {
  ENABLED = "enabled",
  DISABLED = "disabled",
}

/**
 * Valid values of a Call Recordings system preference.
 */
export enum CallRecording {
  ENABLED = "enabled",
  DISABLED = "disabled",
}
