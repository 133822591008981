/**
 * Events of a Twilio Call.
 *
 * @see https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#events
 */
export enum TwilioCallEvent {
  /**
   * Emitted when an incoming Call is accepted.
   * For outgoing calls, the'accept' event is emitted when the media session for the call has finished being set up.
   *
   * @example
   * call.on('accept', call => {
   *   console.log('The incoming call was accepted or the outgoing call's media session has finished setting up.');
   * });
   */
  ACCEPT = "accept",

  /**
   * Emitted when the HTMLAudioElement for the remote audio is created.
   * The handler function receives the HTMLAudioElement for the remote audio as
   * its sole argument. This event can be used to redirect media if your environment
   * supports it. See [WebRTC redirection](https://www.twilio.com/docs/voice/sdks/javascript/changelog#webrtc-api-overrides-beta-1) for more details.
   */
  AUDIO = "audio",
  /**
   * Emitted when the Call instance has been canceled and the call.status() has transitioned to 'closed'.
   *
   * A Call instance can be canceled in two ways:
   * - Invoking call.ignore() on an incoming call
   * - Invoking call.disconnect() on an outgoing call before the recipient has answered
   *
   * @example
   * call.on("cancel", () => {
   *   console.log("The call has been canceled.");
   * });
   */
  CANCEL = "cancel",
  /**
   * Emitted when the media session associated with the Call instance is disconnected.
   *
   * The event listener will receive the Call instance.
   *
   * @example
   * call.on("disconnect", (call) => {
   *   console.log("The call has been disconnected.");
   * });
   */
  DISCONNECT = "disconnect",
  /**
   * Emitted when the Call instance receives an error.
   *
   * The event listener will receive a TwilioError object.
   *
   * @see https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#error-event
   *
   * @example
   * call.on("error", (error) => {
   *   console.log("An error has occurred: ", error);
   * });
   */
  ERROR = "error",
  /**
   * Emitted when the input audio associated with the Call instance is muted or unmuted.
   *
   * The event listener will receive two arguments:
   * - a Boolean indicating whether the input audio for the Call instance is currently muted
   * - the Call instance
   *
   * @example
   * call.on("mute", (isMuted, call) => {
   *   // isMuted is true if the input audio is currently muted
   *   // i.e. The remote participant CANNOT hear local device's input
   *
   *   // isMuted is false if the input audio is currently unmuted
   *   // i.e. The remote participant CAN hear local device's input
   *
   *   isMuted ? console.log("muted") : console.log("unmuted");
   * });
   */
  MUTE = "mute",
  /**
   * Emitted when the Call instance has regained media and/or signaling connectivity.
   *
   * @example
   * call.on('reconnected', () => {
   *   console.log('The call has regained connectivity.')
   * });
   */
  RECONNECTED = "reconnected",
  /**
   * Emitted when the Call instance has lost media and/or signaling connectivity
   * and is reconnecting.
   *
   * The event listener will receive a TwilioError object describing the error
   * that caused the media and/or signaling connectivity loss.
   *
   * You may want to use this event to update the UI so that the user is aware
   * of the connectivity loss and that the SDK is attempting to reconnect.
   *
   * @example
   * call.on('reconnecting', (twilioError) => {
   *   // update the UI to alert user that connectivity was lost
   *   // and that the SDK is trying to reconnect
   *   showReconnectingMessageInBrowser();
   *
   *   // You may also want to view the TwilioError:
   *   console.log('Connectivity error: ', twilioError);
   * });
   */
  RECONNECTING = "reconnecting",
  /**
   * Emitted when call.reject() was invoked and the call.status() is closed.
   *
   * @example
   * call.on("reject", () => {
   *   console.log("The call was rejected.");
   * });
   */
  REJECT = "reject",
  /**
   * Emitted when the Call has entered the ringing state.
   *
   * When using the Dial verb with answerOnBridge=true, the ringing state will
   * begin when the callee has been notified of the call and will transition into
   * open after the callee accepts the call, or closed if the call is rejected or canceled.
   *
   * The parameter hasEarlyMedia denotes whether there is early media available from the callee.
   * If true, the Client SDK will automatically play the early media.
   * Sometimes this is ringing, other times it may be an important message about the call.
   * If false, there is no remote media to play, so the application may want to play its own outgoing ringtone sound.
   *
   * @example
   * call.on("ringing", (hasEarlyMedia) => {
   *   showRingingIndicator();
   *   if (!hasEarlyMedia) {
   *     playOutgoingRinging();
   *   }
   * });
   */
  RINGING = "ringing",
  /**
   * Emitted when a call quality metric has crossed a threshold.
   *
   * The event listener will receive two arguments:
   * - a string of the warning name (ex: 'high-rtt')
   * - an object containing data on the warning
   *
   * This event is raised when the SDK detects a drop in call quality or other
   * conditions that may indicate the user is having trouble with the call.
   * You can implement callbacks on these events to alert the user of an issue.
   *
   * To alert the user that an issue has been resolved, you can listen for the
   * warning-cleared event, which indicates that a call quality metric has
   * returned to normal.
   *
   * For a full list of conditions that will raise a warning event, check the
   * [Voice Insights SDK Events](https://www.twilio.com/docs/voice/voice-insights/api/call/details-sdk-call-quality-events) Reference page.
   *
   * @example
   * call.on('warning', function(warningName, warningData) {
   *  if (warningName === 'low-mos') {
   *    showQualityWarningModal('We have detected poor call quality conditions. You may experience degraded call quality.');
   *    console.log(warningData);
   *    // Prints the following
   *    //   {
   *    //     // Stat name
   *    //     "name": "mos",
   *
   *    //     // Array of mos values in the past 5 samples that triggered the warning
   *    //     "values": [2, 2, 2, 2, 2],
   *
   *    //     // Array of samples collected that triggered the warning.
   *    //     // See sample object format here https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#sample-event
   *    //     "samples": [...],
   *
   *    //     // The threshold configuration.
   *    //     // In this example, low-mos warning will be raised if the value is below 3
   *    //     "threshold": {
   *    //       "name": "min",
   *    //       "value": 3
   *    //     }
   *    //   }
   *    //
   *  }
   *});
   */
  WARNING = "warning",
}
