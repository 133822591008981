/**
 * The direction of a communication.
 */
export enum CommunicationDirection {
  /**
   * A call, chat, email, or SMS from a customer.
   */
  INBOUND = "Inbound",
  /**
   * A call, chat, email, or SMS to a customer.
   */
  OUTBOUND = "Outbound",
}
