import { CopyOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import { useMessageContext } from "../message";

/** Props for `CopyButton` component. */
export type CopyButtonProps = ButtonProps & {
  /** Value to copy. */
  value: string;

  /** Text to display. */
  text?: string;
};

/** Button Component for copying a value to the clipboard. */
export function CopyButton(props: CopyButtonProps) {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const message = useMessageContext();

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  /** Handler function for copying the contact details to the clipboard. */
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      message.info(`Copied ${text} to clipboard!`);
    } catch (err) {
      message.error(`Failed to copy ${text} to clipboard!`);
      console.error("Failed to copy to clipboard!", err);
    }
  };

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <Button
      type="link"
      className="!h-5 !py-0"
      icon={<CopyOutlined />}
      content={props.text}
      onClick={() => copyToClipboard(props.value)}
    />
  );
}
