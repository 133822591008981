import { Auth0User } from "../types";

/**
 * Checks whether the user is authenticated.
 *
 * @returns `true` if the user is authenticated, `false` otherwise.
 */
export const isAuthenticated = (options: { user: Auth0User | null }) => {
  if (!options.user) return false;

  /** now timestamp in ms. */
  const now = new Date().getTime();

  /** expires_at timestamp in ms. */
  const expiresAt = options.user.expires_at * 1000;

  return now < expiresAt;
};
