/**
 * Project-wide constants for the asset routes of the app.
 *
 * So we can replace them easier in the future.
 */
export const ASSET_ROUTE = {
  /**
   * Contains all the routes to the audio assets.
   */
  AUDIO: {
    /**
     * The audio played when the business is closed, the user was prompoted to
     * receive an SMS, but the user did not respond.
     */
    BUSINESS_CLOSED_SMS_NO_RESPONSE:
      "/assets/audio/business-closed-sms-no-response.mp3",
    /**
     * The audio played when the business is closed and can't accept calls.
     */
    BUSINESS_CLOSED_SMS_SUPPORTED:
      "/assets/audio/business-closed-sms-supported.mp3",
    /**
    /**
     * The audio played when the business is closed and can't accept calls.
     */
    BUSINESS_CLOSED_SMS_UNSUPPORTED:
      "/assets/audio/business-closed-sms-unsupported.mp3",
    /**
     * Authentication callback URL.
     */
    CALL_RINGTONE: "/assets/audio/call-ringtone.mp3",
    /**
     * The audio played when the caller is holding in a queue and requested to be
     * sent an SMS instead.
     */
    HOLD_IN_QUEUE_SMS_SEND: "/assets/audio/hold-in-queue-sms-send.mp3",
    /**
     * The audio played when the caller is holding in a queue and is valid to
     * request for an SMS.
     */
    HOLD_IN_QUEUE_SMS_SUPPORTED:
      "/assets/audio/hold-in-queue-sms-supported.mp3",
    /**
     * The audio played when the caller is holding in a queue and is valid to
     * request for an SMS.
     */
    HOLD_IN_QUEUE_SMS_UNSUPPORTED:
      "/assets/audio/hold-in-queue-sms-unsupported.mp3",
    /**
     * The audio played to welcome a caller.
     */
    WELCOME: "/assets/audio/welcome.mp3",
  },
  /**
   * Contains all the routes to the image assets.
   */
  IMAGE: {
    /**
     * The logo of The Pique Lab.
     */
    CLOUD_CONTACT_CENTER_LOGO: "/assets/images/favicon.png",
  },
};
