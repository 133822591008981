/**
 * Time Zone Constants
 *
 * Provides with the time zone for each location.
 */
export enum TimeZone {
  UNIVERSAL_TIME_COORDINATED = "UTC",
  GREENWICH_MEAN_TIME = "GMT",
  NEW_YORK = "America/New_York", // Eastern Standard Time (USA)
  LOS_ANGELES = "America/Los_Angeles", // Pacific Standard Time (USA)
  CHICAGO = "America/Chicago", // Central Standard Time (USA)
  DENVER = "America/Denver", // Mountain Standard Time (USA)
  HALIFAX = "America/Halifax", // Atlantic Standard Time (Canada)
  ST_JOHNS = "America/St_Johns", // Newfoundland Standard Time (Canada)

  // European Time Zones
  LISBON = "Europe/Lisbon", // Western European Time
  BERLIN = "Europe/Berlin", // Central European Time
  HELSINKI = "Europe/Helsinki", // Eastern European Time
  LONDON = "Europe/London", // British Summer Time
  PARIS = "Europe/Paris", // Central European Summer Time
  ISTANBUL = "Europe/Istanbul", // Eastern European Summer Time

  // Africa
  LAGOS = "Africa/Lagos", // West Africa Time
  HARARE = "Africa/Harare", // Central Africa Time
  NAIROBI = "Africa/Nairobi", // East Africa Time
  JOHANNESBURG = "Africa/Johannesburg", // South Africa Standard Time

  // Asia
  KOLKATA = "Asia/Kolkata", // Indian Standard Time
  KARACHI = "Asia/Karachi", // Pakistan Standard Time
  BANGKOK = "Asia/Bangkok", // Indochina Time
  SHANGHAI = "Asia/Shanghai", // China Standard Time
  TOKYO = "Asia/Tokyo", // Japan Standard Time
  SEOUL = "Asia/Seoul", // Korea Standard Time
  SINGAPORE = "Asia/Singapore", // Singapore Time
  HONG_KONG = "Asia/Hong_Kong", // Hong Kong Time

  // Australia and Oceania
  PERTH = "Australia/Perth", // Australian Western Standard Time
  ADELAIDE = "Australia/Adelaide", // Australian Central Standard Time
  SYDNEY = "Australia/Sydney", // Australian Eastern Standard Time
  AUCKLAND = "Pacific/Auckland", // New Zealand Standard Time

  // Middle East
  RIYADH = "Asia/Riyadh", // Arabia Standard Time
  DUBAI = "Asia/Dubai", // Gulf Standard Time
  TEHRAN = "Asia/Tehran", // Iran Standard Time

  // Latin America
  SAO_PAULO = "America/Sao_Paulo", // Brasilia Summer Time
  ARGENTINA = "America/Argentina/Buenos_Aires", // Argentina Time
  SANTIAGO = "America/Santiago", // Chile Standard Time

  // Pacific Time Zones
  HONOLULU = "Pacific/Honolulu", // Hawaii Standard Time
  MIDWAY = "Pacific/Midway", // Samoa Standard Time
  GUAM = "Pacific/Guam", // Chamorro Standard Time

  // Russia
  MOSCOW = "Europe/Moscow", // Moscow Standard Time
}

/**
 * Time Zone Offset Constants
 *
 * Provides with the UTC offset for each time zone.
 */
export enum TimeZoneOffsetUTC {
  UNIVERSAL_TIME_COORDINATED = "+00:00",
  NEW_YORK = "-05:00", // Eastern Standard Time (USA)
  LOS_ANGELES = "-08:00", // Pacific Standard Time (USA)
  CHICAGO = "-06:00", // Central Standard Time (USA)
  DENVER = "-07:00", // Mountain Standard Time (USA)
  HALIFAX = "-04:00", // Atlantic Standard Time (Canada)
  ST_JOHNS = "-03:30", // Newfoundland Standard Time (Canada)

  // European Time Zones
  LISBON = "+00:00", // Western European Time
  BERLIN = "+01:00", // Central European Time
  HELSINKI = "+02:00", // Eastern European Time
  LONDON = "+00:00", // British Summer Time
  PARIS = "+01:00", // Central European Summer Time
  ISTANBUL = "+03:00", // Eastern European Summer Time

  // Africa
  LAGOS = "+01:00", // West Africa Time
  HARARE = "+02:00", // Central Africa Time
  NAIROBI = "+03:00", // East Africa Time
  JOHANNESBURG = "+02:00", // South Africa Standard Time

  // Asia
  KOLKATA = "+05:30", // Indian Standard Time
  KARACHI = "+05:00", // Pakistan Standard Time
  BANGKOK = "+07:00", // Indochina Time
  SHANGHAI = "+08:00", // China Standard Time
  TOKYO = "+09:00", // Japan Standard Time
  SEOUL = "+09:00", // Korea Standard Time
  SINGAPORE = "+08:00", // Singapore Time
  HONG_KONG = "+08:00", // Hong Kong Time

  // Australia and Oceania
  PERTH = "+08:00", // Australian Western Standard Time
  ADELAIDE = "+09:30", // Australian Central Standard Time
  SYDNEY = "+10:00", // Australian Eastern Standard Time
  AUCKLAND = "+12:00", // New Zealand Standard Time

  // Middle East
  RIYADH = "+03:00", // Arabia Standard Time
  DUBAI = "+04:00", // Gulf Standard Time
  TEHRAN = "+03:30", // Iran Standard Time

  // Latin America
  SAO_PAULO = "-03:00", // Brasilia Summer Time
  ARGENTINA = "-03:00", // Argentina Time
  SANTIAGO = "-04:00", // Chile Standard Time

  // Pacific Time Zones
  HONOLULU = "-10:00", // Hawaii Standard Time
  MIDWAY = "-11:00", // Samoa Standard Time
  GUAM = "+10:00", // Chamorro Standard Time

  // Russia
  MOSCOW = "+03:00", // Moscow Standard Time
}
