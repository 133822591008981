import { Call, CallStatus } from "@/components/client/graphql";
import { Auth0User } from "@/components/common/auth0/types";
import { ErrorMessage } from "@/components/common/error/enumerations";

/**
 * Identifies if the user can view the call.
 * - Call is assigned to a user
 * - Call is unassigned and:
 *    - Call is canceled
 *    - Call is completed
 *    - Call is missed
 *    - Call is rejected
 *
 * @returns True if the user can view the call.
 */
export const isUserViewable = ({
  call,
  user,
}: {
  /**
   * The call to evaluate.
   */
  call: Pick<Call, "status" | "userId">;
  /**
   * The user to check if they can view the call.
   */
  user: Pick<Auth0User, "id">;
}) => {
  if (!call.status) {
    throw new Error(`${ErrorMessage.MISSING_ARGUMENT}: call.status`);
  }

  if (!user.id) {
    throw new Error(`${ErrorMessage.MISSING_ARGUMENT}: user.id`);
  }

  // A call is viewable by the user if it is assigned to him.
  if (!!call.userId && call.userId === user.id) {
    return true;
  }

  switch (call.status) {
    /**
     * A queued call is not viewable by the user at this point because it is not
     * assigned to him based on the if clause above.
     */
    case CallStatus.Queued:
      return false;
    // A call is viewable by the user if it is under these statuses.
    case CallStatus.Failed:
    case CallStatus.Canceled:
    case CallStatus.Completed:
    case CallStatus.Missed:
    case CallStatus.Rejected:
      return true;
    // A call is not viewable otherwise.
    default:
      return false;
  }
};
