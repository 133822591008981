"use client";

/**
 * Project components.
 */
import { Icon } from "@/components/client/icon";

/**
 * A section that is displayed when no communication log is selected.
 * This displays an icon and a text that says "Select a log".
 */
function CommunicationLogSelectALogPlaceholder() {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <div className="w-full h-full flex flex-col justify-center items-center gap-2">
      <div>
        <Icon
          className="!text-6xl !text-tpl-navy-light"
          src="inbox-empty-with-shadow"
        />
        {/* <InboxOutlinedIcon className="!text-6xl !text-tpl-navy-light" /> */}
      </div>
      <div className="text-center text-tpl-navy-light text-sm">
        Select a log
      </div>
    </div>
  );
}

export default CommunicationLogSelectALogPlaceholder;
