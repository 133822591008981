import { Device } from "@twilio/voice-sdk";

/**
 * Events of a Twilio Device.
 *
 * @see https://www.twilio.com/docs/voice/client/javascript/device#events
 */
export enum TwilioDeviceEvent {
  /**
   * Emitted when the Device has been destroyed.
   *
   * @example
   * const device = new Device(token);
   *
   * device.on("destroyed", () => {
   *   // Do something
   * });
   */
  DESTROYED = Device.EventName.Destroyed,
  /**
   * Emitted when the Device instance receives an error.
   * The event listener will receive a TwilioError and when applicable, a reference to the Call object that was active when the error occured.
   *
   * See the TwilioError section below for more information on the TwilioError object.
   *
   * - causes string[]	A list of possible causes for the error
   * - code number	The numerical code associated with this error
   * - description string	A description of what the error means
   * - explanation string	An explanation of when the error may be observed
   * - message string	Any further information discovered and passed along at runtime.
   * - name string	The name of this error
   * - originalError (optional) string	The original error received from the external system, if any
   * - solutions string[]	A list of potential solutions for the error
   *
   * @example
   * const device = new Device(token);
   *
   * device.on("error", (twilioError, call) => {
   *   console.log("An error has occurred: ", twilioError);
   * });
   */
  ERROR = Device.EventName.Error,
  /**
   * Emitted when an incoming Call is received. An event listener will receive the Call object representing the incoming Call.
   * You can interact with the call object using its public APIs, or you can forward it to a different Device using
   * [device.connect()](https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice#deviceconnectconnectoptions)
   * and [call.connectToken](https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#callconnecttoken),
   * enabling your application to receive multiple incoming calls for the same identity.
   *
   * Important: When forwarding a call, the token for the target Device instance needs to have the same identity as the token used in the Device that originally received the call
   * 
   * @example
   * const receiverDevice = new Device(token, options);
   * await receiverDevice.register();* 
 
   * receiverDevice.on('incoming', (call) => {
   *   // Forward this call to a new Device instance using the call.connectToken string.
   *   forwardCall(call.connectToken);
   * });* 
 
   * // The forwardCall function may look something like the following.
   * async function forwardCall(connectToken) {
   *   // For each incoming call, create a new Device object. This ensures individual
   *   // interaction with each call, without affecting other calls.
   *   // IMPORTANT: The token for this new device needs to have the same identity
   *   // as the token used in the receiverDevice.
   *   const device = new Device(token, options);
   *   const call = await device.connect({ connectToken });** 
   *   // Destroy the `Device` after the call is completed
   *   call.on('disconnect', () => device.destroy());
   * }
   */
  INCOMING = Device.EventName.Incoming,
  /**
   * Emitted when the Device instance is registered and able to receive incoming calls.
   *
   * @example
   * const device = new Device(token);
   *
   * device.on("registered", () => {
   *   // Do something
   * });
   */
  REGISTERED = Device.EventName.Registered,
  /**
   * Emitted when the Device instance is registering with Twilio to receive incoming calls.
   *
   * @example
   * const device = new Device(token);
   *
   * device.on('registered', () => {
   *     // Do something
   * });
   */
  REGISTERING = Device.EventName.Registering,
  /**
   * Emitted when the Device instance's AccessToken is about to expire.
   * Use the refreshTokenMs property on the [DeviceOptions](https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice#deviceoptions)
   * object to set a custom warning time. The default is 10 seconds (10000 milliseconds) prior to the AccessToken expiring.
   * You can use this event along with device.updateToken() to automatically retrieve a new AccessToken and update the
   * AccessToken on the Device instance, as shown in the example below:
   *
   * @example
   * device.on("tokenWillExpire", () => {
   *   const token = getNewTokenViaAjax();
   *   device.updateToken(token);
   * });
   */
  TOKEN_WILL_EXPIRE = Device.EventName.TokenWillExpire,
  /**
   * Emitted when the Device instance has unregistered with Twilio.
   *
   * @example
   * const device = new Device(token);
   *
   * device.on('unregistered', () =>
   *     // Do something
   * });
   */
  UNREGISTERED = Device.EventName.Unregistered,
}

export default TwilioDeviceEvent;
