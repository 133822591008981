"use client";

/**
 * Third-party libraries.
 */
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { message as antdMessage } from "antd";
import React, { PropsWithChildren } from "react";

/**
 * Types for the message context.
 */
export type MessageContext = {
  /** Displays a toast message prefixed with an (i) icon. */
  info: (message: string) => void;
  /** Displays an toast message prefixed with a (✓) icon. */
  success: (message: string) => void;
  /** Displays an toast message prefixed with an (x) icon. */
  error: (message: string) => void;
  /** Displays an toast message prefixed with a (⚠) icon. */
  warning: (message: string) => void;
};

/**
 * Message context.
 */
const MessageContext = React.createContext<MessageContext>({
  info: () => {},
  success: () => {},
  error: () => {},
  warning: () => {},
});

/**
 * Hook for displaying toast messages.
 */
export const useMessageContext = () => {
  return React.useContext(MessageContext);
};

/**
 * Context provider for displaying toast messages.
 */
export const MessageContextProvider = ({ children }: PropsWithChildren) => {
  /**
   * Ant Design notification.
   *
   * Add more options here: https://ant.design/components/message#messageconfig
   * @example duration: 3  // in seconds
   */
  const [messageApi, contextHolder] = antdMessage.useMessage();

  /** Wrap around the info method for adding the custom icon. */
  const info = (message: string) => {
    messageApi.info({ icon: <InfoCircleOutlined />, content: message });
  };

  /** Wrap around the `success` method for adding the custom icon. */
  const success = (message: string) => {
    messageApi.success({ icon: <CheckCircleOutlined />, content: message });
  };

  /** Wrap around the `error` method for adding the custom icon. */
  const error = (message: string) => {
    messageApi.error({ icon: <CloseCircleOutlined />, content: message });
  };

  /** Wrap around the `warning` method for adding the custom icon. */
  const warning = (message: string) => {
    messageApi.warning({ icon: <WarningOutlined />, content: message });
  };

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <MessageContext.Provider
      value={{
        info,
        success,
        error,
        warning,
      }}
    >
      {contextHolder}
      {children}
    </MessageContext.Provider>
  );
};
