import { TimeZone } from "@/components/common/time";
import { DayOfWeek } from "@prisma/client";
import dayjs from "dayjs";

/**
 * Gets the date from the date object.
 */
export function getDate({
  date,
}: {
  /**
   * Date and time to get the time from.
   */
  date: Date;
}) {
  let day: string | number = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  /**
   * Zero (0) padded day.
   */
  day = day < 10 ? "0" + day : day;

  return day + " " + monthNames[month] + " " + year;
}

/**
 * Gets the time from a date object.
 * Returns hh:mm AM/PM format.
 */
export function getTime({
  date,
}: {
  /**
   * Date and time to get the time from.
   */
  date: Date;
}) {
  let hours = date.getHours();
  let minutes: number | string = date.getMinutes();
  let seconds: number | string = date.getSeconds();
  let milliseconds: number | string = date.getMilliseconds();

  const amPm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  // 0 Hour should be 12.
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  milliseconds =
    milliseconds < 10
      ? "00" + milliseconds
      : milliseconds < 100
        ? "0" + milliseconds
        : milliseconds;

  return (
    // hours + ":" + minutes + " " + amPm
    hours + ":" + minutes + ":" + seconds + " " + amPm
    // hours + ":" + minutes + ":" + seconds + "." + milliseconds + " " + amPm
  );
}

/**
 * Get date and time from a date object.
 */
export function getDateTime({
  date,
}: {
  /**
   * Date and time to get the time from.
   */
  date: Date;
}) {
  return (
    getDate({
      date,
    }) +
    " " +
    getTime({
      date,
    })
  );
}

/**
 * Check if date and time is today.
 */
export function isToday({
  date,
}: {
  /**
   * Date to check.
   */
  date: Date;
}) {
  /**
   * Date today.
   */
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

/**
 * Check if date is yesterday.
 */
export function isYesterday({
  date,
}: {
  /**
   * Date to check.
   */
  date: Date;
}) {
  const today = new Date();
  const yesterday = new Date(today);

  // Subtract one day from today
  yesterday.setDate(today.getDate() - 1);

  return (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  );
}

/**
 * Get the UTC offset of a time zone.
 *
 * @returns {string} The UTC offset in HH:MM format.
 *
 * @example
 * +08:00
 */
export function getUtcTimeZoneOffset({
  timeZone,
}: {
  /**
   * Time zone to get the UTC offset from.
   */
  timeZone: TimeZone;
}) {
  const currentTimeInTimezone = dayjs().tz(timeZone);

  // Get the UTC offset in minutes
  const UTC_TIME_ZONE_OFFSET_MINUTES = currentTimeInTimezone.utcOffset(); // Offset in minutes

  /**
   * UTC Time Zone Offset in Hours.
   *
   * This is in decimal form.
   *
   * @example
   * 8.5
   */
  const UTC_TIME_ZONE_OFFSET_HOURS = UTC_TIME_ZONE_OFFSET_MINUTES / 60;

  /**
   * Formats a decimal number to a UTC offset string.
   *
   * @returns {string} The formatted UTC offset string.
   *
   * @example
   * +08:30
   */
  const formatOffset = ({
    offset,
  }: {
    /**
     * Decimal number representing the offset.
     */
    offset: number;
  }): string => {
    const sign = offset >= 0 ? "+" : "-";
    const absoluteOffset = Math.abs(offset);
    const hours = Math.floor(absoluteOffset);
    const minutes = (absoluteOffset - hours) * 60;
    return `${sign}${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
  };

  /**
   * UTC Time Zone Offset in HH:MM format.
   */
  const UTC_TIME_ZONE_OFFSET = formatOffset({
    offset: UTC_TIME_ZONE_OFFSET_HOURS,
  });
  return UTC_TIME_ZONE_OFFSET;
}

/**
 * Get the corresponding `DayOfWeek` enum from a date object.
 */
export function getDayOfWeek({ date }: { date: Date }) {
  const day = dayjs(date).day();
  switch (day) {
    case 0:
      return DayOfWeek.SUNDAY;
    case 1:
      return DayOfWeek.MONDAY;
    case 2:
      return DayOfWeek.TUESDAY;
    case 3:
      return DayOfWeek.WEDNESDAY;
    case 4:
      return DayOfWeek.THURSDAY;
    case 5:
      return DayOfWeek.FRIDAY;
    case 6:
      return DayOfWeek.SATURDAY;

    default:
      throw new Error("Invalid day of the week");
  }
}

/** Params for `isWithinRange`. */
export type IsWithinRangeParams = {
  /** Start date time of the date range. (inclusive) */
  startDate: Date;
  /** End date time of the date range. (inclusive) */
  endDate: Date;
  /** Date to check. Defaults to `new Date()` */
  date?: Date;
  /** Preferred timezone to use. Defaults to `TimeZone.SINGAPORE` */
  timezone?: TimeZone;
};

/**
 * Checks if the current date is within the date range.
 * The date range is inclusive.
 *
 * @returns `true` if the current date is within the date range, `false` otherwise.
 */
export function isWithinRange(params: IsWithinRangeParams) {
  const timezone = params.timezone || TimeZone.SINGAPORE;
  const startDateTime = dayjs(params.startDate).tz(timezone);
  const endDateTime = dayjs(params.endDate).tz(timezone);
  const currentDateTime = dayjs(params.date || new Date()).tz(timezone);

  return (
    (currentDateTime.isSame(startDateTime) ||
      currentDateTime.isAfter(startDateTime)) &&
    (currentDateTime.isBefore(endDateTime) ||
      currentDateTime.isSame(endDateTime))
  );
}
