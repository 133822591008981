/**
 * Get the initials of the given word.
 */
export function getInitials({
  input,
  maxLength = 2,
}: {
  /**
   * The input string to get the initials from.
   */
  input: string;
  /**
   * The maximum length of the initials. Defaults to `2`.
   */
  maxLength?: number;
}) {
  if (!input) return null;

  /**
   * First characters of each word in the input.
   */
  const firstCharactersOfEachWord = input.match(/\b(\w)/g);

  if (!firstCharactersOfEachWord) {
    return null;
  }

  /**
   * Upper case initials.
   */
  let initials = firstCharactersOfEachWord.map((word) => word.toUpperCase());

  initials = initials.slice(0, maxLength);

  return initials.join("");
}

/**
 * Capitalizes the first letter of the given string.
 */
export function toTitleCase({ str }: { str: string }) {
  if (!str) return null;
  return str.charAt(0).toUpperCase() + str.slice(1);
}
