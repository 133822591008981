/**
 * Project-wide constants for the api routes of the app.
 *
 * So we can replace them easier in the future.
 */
export enum ApiRoute {
  /**
   * Apollo GraphQL Endpoint.
   * HTTP POST
   */
  GRAPHQL = "/api/graphql",
  /**
   * Authentication login URL.
   * HTTP GET
   */
  AUTHENTICATION_LOGIN = "/api/auth/login",
  /**
   * Authentication logout URL.
   * HTTP GET
   */
  AUTHENTICATION_LOGOUT = "/api/auth/logout",
  /**
   * Authentication me URL.
   * HTTP GET
   */
  AUTHENTICATION_ME = "/api/auth/me",
  /**
   * Authentication me URL.
   * HTTP GET
   */
  AUTHENTICATION_REFRESH = "/api/auth/refresh",
  /**
   * Authentication callback URL.
   * HTTP GET
   */
  AUTHENTICATION_REDIRECT = "/api/auth/callback",
  /**
   * API path to do testing.
   * HTTP POST
   */
  TEST = "/api/test",
  /**
   * API path to request a Twilio Device token.
   * HTTP POST
   */
  TWILIO_TOKEN = "/api/twilio/token",
  /**
   * API path to join a user to an existing conference call.
   * HTTP POST
   *
   * Use this to connect a user to:
   * - An existing inbound conference call.
   *    - Inbound calls are made by the customer and is connected to a conference.
   * - An existing outbound conference call.
   *    - Outbound calls are created using the backend API and is connected to a conference.
   *    - A user is then expected to join the created conference and wait for the customer to join or for the call to timeout.
   */
  TWILIO_VOICE_CONFERENCE_JOIN = "/api/twilio/voice/conference/join",
  /**
   * API path to handle Twilio Voice conference call status changes.
   * HTTP POST
   */
  TWILIO_VOICE_CONFERENCE_STATUS_CALLBACK = "/api/twilio/voice/conference/status-change",
  /**
   * @deprecated For removal.
   * API path to handle Twilio Voice connect to agent.
   * HTTP POST
   */
  TWILIO_VOICE_CONNECT_TO_AGENT = "/api/twilio/voice/connect/to/agent",
  /**
   * API path to handle Twilio Voice call hold in queue.
   * HTTP POST
   */
  TWILIO_VOICE_QUEUE_HOLD = "/api/twilio/voice/hold",
  /**
   * API path to play Twilio Voice call ringtone.
   * HTTP POST
   */
  TWILIO_VOICE_PLAY_RINGTONE = "/api/twilio/voice/play-ringtone",
  /**
   * API path to handle Twilio Voice call queue.
   * HTTP POST
   */
  TWILIO_VOICE_QUEUE = "/api/twilio/voice/queue",
  /**
   * API path to handle initial incoming Twilio Voice calls.
   * HTTP POST
   */
  TWILIO_VOICE_ROUTER = "/api/twilio/voice/router",
  /**
   * API path to handle Twilio Voice send SMS.
   * HTTP POST
   */
  TWILIO_VOICE_SEND_SMS = "/api/twilio/voice/sms",
  /**
   * API path to handle Twilio Voice status changes.
   * HTTP POST
   */
  TWILIO_VOICE_STATUS_CHANGE_CALLBACK = "/api/twilio/voice/status-change",
  /**
   * API path to handle Twilio Voice recoding status changes.
   * HTTP POST
   */
  TWILIO_VOICE_RECORDING_STATUS_CHANGE_CALLBACK = "/api/twilio/voice/recording/status-change",
}
