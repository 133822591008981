/**
 * Third party libraries
 */
import dayjs from "dayjs";

/**
 * Project components
 */
import { TimeZone } from "@/components/common/time";
import { BusinessHour } from "@prisma/client";

/**
 * Arguments for the normalize business hour range function.
 */
type NormalizeBusinessHourRangeToCurrentOrNextWeekArgs = {
  /**
   * The end date time of the business hour.
   */
  endDateTime: Date;
  /**
   * The start date time of the business hour.
   */
  startDateTime: Date;
  /**
   * The time zone of the business hour.
   */
  timeZone: TimeZone;
};

/**
 * Normalized business hour range.
 */
type NormalizedBusinessHourRange = {
  /**
   * The normalized start date time.
   */
  normalizedStartDateTime: Date;
  /**
   * The normalized end date time.
   */
  normalizedEndDateTime: Date;
};

/**
 * Normalize the business hour range.
 *
 * - If the end date time is before the start date time:
 *    - The end date time is increased by 1 day.
 * - If the current date time is past the end date time:
 *    - The start date time is set to the next week on the same day.
 *    - The end date time is set to the next week on the same day.
 */
export const normalizeBusinessHourRangeToCurrentOrNextWeek = ({
  endDateTime,
  startDateTime,
  timeZone,
}: NormalizeBusinessHourRangeToCurrentOrNextWeekArgs): NormalizedBusinessHourRange => {
  // Set the default time zone for dayjs operations.
  dayjs.tz.setDefault(timeZone);

  const currentDateTime = dayjs();

  /**
   * The business hour start date time.
   */
  let _startDateTime = dayjs(startDateTime);

  /**
   * The business hour end date time.
   */
  let _endDateTime = dayjs(endDateTime);

  /**
   * ===========================================================================
   * Any code beyond this point is only executed if the current date time is
   * past the end date time.
   * ===========================================================================
   */

  /**
   * Current day is equal to the start date time day in the defined time zone.
   */
  const isCurrentDayEqualToStartDateTimeDay =
    currentDateTime.day() === _startDateTime.day();

  let nextStartDateTime = isCurrentDayEqualToStartDateTimeDay
    ? currentDateTime
    : currentDateTime.add(1, "week");

  /**
   * Set the hours, minutes, seconds, millseconds, and day of the week to the
   * start date time.
   *
   * Setting the day of the week causes the date to be set to the current day
   * or the same day next week.
   */
  nextStartDateTime = nextStartDateTime
    .set("hour", _startDateTime.hour())
    .set("minute", _startDateTime.minute())
    .set("second", _startDateTime.second())
    .set("millisecond", _startDateTime.millisecond())
    .day(_startDateTime.day());

  /**
   * Clone the start date time to the end date time.
   *
   * Used as a baseline for computing the end date time.
   */
  let nextEndDateTime = dayjs(nextStartDateTime);

  /**
   * Set the hours, minutes, seconds, millseconds, and day of the week to the
   * end date time.
   *
   * Setting the day of the week causes the date to be set to the current day
   * or the same day next week.
   */
  nextEndDateTime = nextEndDateTime
    .set("hour", _endDateTime.hour())
    .set("minute", _endDateTime.minute())
    .set("second", _endDateTime.second())
    .set("millisecond", _endDateTime.millisecond())
    .day(_endDateTime.day());

  /**
   * Increase the end date time by 1 day if it is before the start date time.
   * This is assuming that the time set was intended for the next day.
   */
  if (nextEndDateTime.isBefore(nextStartDateTime)) {
    nextEndDateTime = nextEndDateTime.add(1, "day");
  }

  return {
    normalizedStartDateTime: nextStartDateTime.toDate(),
    normalizedEndDateTime: nextEndDateTime.toDate(),
  };
};

/**
 * Checks the business hour settings and returns if the business is open or not.
 */
export const isBusinessOpen = (params: {
  /** The business hour records. (Only uses the following fields: active, startTime, and endTime) */
  businessHours: Pick<BusinessHour, "active" | "startTime" | "endTime">[];
  /** The time zone of the business hour. */
  timeZone?: TimeZone;
}) => {
  const timezone = params.timeZone ?? TimeZone.SINGAPORE;
  const currentDateTime = dayjs(new Date()).tz(timezone);

  /**
   * Check if business hours is open.
   *
   * The business is open if the current date and time is on or after the start
   * date time and before or on end date time of any of the business hour records.
   */
  for (const businessHour of params.businessHours) {
    const startDateTime = dayjs(businessHour.startTime).tz(timezone);

    /**
     * End date time of the business hour schedule.
     */
    const endDateTime = dayjs(businessHour.endTime).tz(timezone);

    /**
     * Check if the current date time is within the business hour schedule
     * and the business hour is active.
     */
    const isCurrentDateTimeWithinSchedule =
      (currentDateTime.isSame(startDateTime) ||
        currentDateTime.isAfter(startDateTime)) &&
      (currentDateTime.isBefore(endDateTime) ||
        currentDateTime.isSame(endDateTime));

    if (
      // Current time is on or after the start date time and before or on end date time.
      businessHour.active &&
      isCurrentDateTimeWithinSchedule
    ) {
      return true;
    }
  }

  return false;
};
